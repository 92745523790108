'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { HomeSwiper } from './lib/HomeSwiper'
import { Recruit } from './lib/Recruit'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new HomeSwiper()
new Recruit()