import Swiper from 'swiper'
import { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle';

export class HomeSwiper {
  constructor() {
    const swiper_award = new Swiper('.sec-top-award .list', {
      modules: [Navigation, Scrollbar],
      slidesPerView: 1,
      spaceBetween: 25,
      breakpoints: {
        768: {
          slidesPerView: 3,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    const swiper_shoplist = new Swiper('.sec-top-shoplist .list', {
      modules: [Navigation, Scrollbar, Pagination, Autoplay],
      slidesPerView: 1.13284,
      spaceBetween: 16,
      pagination: {
        el: '.swiper-pagination',
        clickable: true, // ページネーションをクリック可能にするかどうか
      },
      speed: 1500,
      autoplay: {     //追記
        delay: 3000,   //追記
      },
      navigation: {
        nextEl: '.swiper-button-next01',
        prevEl: '.swiper-button-prev01',
      },
      breakpoints: {
        768: {
          slidesPerView: 3.4735,
          spaceBetween: 33,
        }
      },
    });
    const target = document.getElementsByClassName("js-fix")[0];
    if (!target) return
    const target02 = document.getElementsByClassName("is-body")[0];
    if (!target02) return
    const viewHeight = document.documentElement.clientHeight;
    const rectLoad = target.getBoundingClientRect().height;
    const fixedBound = viewHeight - rectLoad;

    window.addEventListener("scroll", function () {
      // const wrapper = document.getElementsByClassName("js-fix-wrapper")[0];
      // const rect = target.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // const myTop = rect.top + scrollTop;
      if (scrollTop > fixedBound) {
        target.classList.add("is-fixed");
        target02.classList.add("is-fixed");
      } else {
        target.classList.remove("is-fixed");
        target02.classList.remove("is-fixed");
      }
    });
    //テキストのカウントアップ+バーの設定
    var bar = new ProgressBar.Line(splash_text, {//id名を指定
      easing: 'easeInOut',//アニメーション効果linear、easeIn、easeOut、easeInOutが指定可能
      duration: 1000,//時間指定(1000＝1秒)
      strokeWidth: 0.2,//進捗ゲージの太さ
      color: '#555',//進捗ゲージのカラー
      trailWidth: 0.2,//ゲージベースの線の太さ
      trailColor: '#font-family: "Gilda Display", serif;',//ゲージベースの線のカラー
      text: {//テキストの形状を直接指定       
        style: {//天地中央に配置
          position: 'absolute',
          left: '50%',
          top: '50%',
          padding: '0',
          margin: '36px 0 0 0',//バーより上に配置
          transform: 'translate(-50%,-50%)',
          'font-size': '34px',
          color: '#424849',
        },
        autoStyleContainer: false //自動付与のスタイルを切る
      },
      step: function (state, bar) {
        bar.setText(Math.round(bar.value() * 100) + ' %'); //テキストの数値
      }
    });

    //アニメーションスタート
    bar.animate(1.0, function () {//バーを描画する割合を指定します 1.0 なら100%まで描画します
      $("#splash").delay(500).fadeOut(800);//アニメーションが終わったら#splashエリアをフェードアウト
    });
  }
}
