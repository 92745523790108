import Swiper from 'swiper'
import { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle';
export class Recruit {
  constructor() {
    // const swiper1 = new Swiper(".shop-slider", {
    //   slidesPerView: "auto",
    //   loop: true,
    // });

    const swiper2 = new Swiper(".voice-slider", {
      modules: [Navigation, Scrollbar, Pagination, Autoplay],
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      speed: 1500,
      autoplay: {     //追記
        delay: 3000,   //追記
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true, // ページネーションをクリック可能にするかどうか
      },
      navigation: {
        nextEl: '.swiper-button-next01',
        prevEl: '.swiper-button-prev01',
      },
    });

  }
}
